

























































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import pagination from '@/components/common/pagination/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import AccessImage from '@/components/dashboard/AccessImage/index.vue'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { tableScrollTop } from '@/utils/dom'
import _ from 'lodash'
import { is } from '@/utils/helpers'
import { nonVehicleTypes, nonVehicleColors } from '@/utils/index'

@Component({
  name: 'index',
  components: {
    pagination,
    pageTitle,
    AccessImage,
    SecurityText
  }
})
export default class NonVehiclePage extends Vue {
  @Ref('paginationNode') readonly paginationNode: pagination
  private query = {
    limit: 20,
    offset: 0
  }
  filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }
  private showFlag = false
  private vehicleList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private personImg: any[] = [
    {
      name: '抓拍图',
      url: null,
      EqualWH: true,
      BigPicture: false
    },
    {
      name: '抓拍全景图',
      url: null,
      EqualWH: false,
      Typesetting: 'horizontal',
      BigPicture: true
    }
  ]

  get type() {
    return nonVehicleTypes
  }

  get colors() {
    return nonVehicleColors
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  mounted() {
    this.getList(this.query.limit, 0)
  }

  deactivated() {
    this.showFlag = false
  }

  private async pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }
  async getList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const resp = await this.getVehicleList(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.vehicleList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getVehicleList(pageSize = 20, offset = 0) {
    const val = this.filterData
    const [startTime, endTime] = val.dateRange

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset: offset,
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root],
      ..._.pickBy(_.omit(this.filterData, ['dateRange']), is.ava)
    }
    return {
      data: {
        count: 0,
        data: []
      }
    }
    // return this.$api.base.getPassagesSearch(par)
  }

  private handleFilter() {
    this.paginationNode.initPage({ current: 1 })
    this.getList(this.query.limit, 0)
  }

  private async seePassImg(obj: AnyObj) {
    if (obj.targetImageUrl || obj.sceneImageUrl) {
      this.showFlag = true
      this.personImg[0].url = obj.targetImageUrl
      this.personImg[1].url = obj.sceneImageUrl
    }
  }
}
