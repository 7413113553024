













import { Vue, Component } from 'vue-property-decorator'
import pageTitle from '@/components/common/pageTitle.vue'
import Vehicle from './Vehicle/index.vue'
import NonVehicle from './NonVehicle/index.vue'

@Component({
  name: 'detailPage',
  components: {
    pageTitle,
    NonVehicle,
    Vehicle
  }
})
export default class VehicleRecordsPage extends Vue {
  private isShowNonVehicle = false
  activeTag = 'vehicle'
  first = true
  tabClick(tab: AnyObj) {
    if (tab.index === '0') {
      this.activeTag = 'vehicle'
    }
    if (tab.index === '1') {
      this.activeTag = 'nonVehicle'
      if (this.first) this.isShowNonVehicle = true
      this.first = false
    }
  }
}
